.tooltipster-punk {
	border-radius: 5px; 
	border-bottom: 3px solid #f71169;
	background: #2a2a2a;
	color: #fff;
}
.tooltipster-punk .tooltipster-content {
	font-family: 'Courier', monospace;
	font-size: 14px;
	line-height: 16px;
	padding: 8px 10px;
}