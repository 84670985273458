@import "main.less";
/* Sticky footer styles
-------------------------------------------------- */

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
  font-family: "Roboto", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  /*background: #fff url("../images/bg-noise.png") repeat top left;*/
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  /*background-color: @black;*/
}


::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-left: 1px solid #ddd;
  -webkit-border-radius: 0;
  border-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: @brand-primary;
}

::-webkit-scrollbar-thumb:window-inactive {

}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  text-align: center;
  margin: 0 auto;
}

.img-responsive-height {
  display: block;
  width: auto;
  max-height: 100%;
  text-align: center;
  margin: 0 auto;
}

a:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}

.navbar-default {
  -webkit-box-shadow: 1px 1px 4px #999;
  -moz-box-shadow: 1px 1px 4px #999;
  -o-box-shadow: 1px 1px 4px #999;
  -ms-box-shadow: 1px 1px 4px #999;
  box-shadow: 1px 1px 6px #999;
  background: @soft-black;
  /*background: url(../images/noise-head.jpg) repeat;*/
  border-color: @new-white;
  .navbar-text {
    color: @black;
  }
}

.nav > li > a:hover {
  text-decoration: none;
  background-color: @brand-primary;
}

.nav > li > a:focus {
  text-decoration: none;
  background-color: #D5DAD9;
}

#preview-cancel {
  position:absolute;
  top:10px;
  right:10px;
  z-index:1051;
}

.modal-dialog {
  .browser-screen-loading-content {
    text-align: center;
    height: 2em;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 3em;
    left: 0;
    margin: auto;
  }

  .modal-footer {
    margin-top: 20px;
  }

  .loading-dots {
    margin-left: -1.5em;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-animation: loading-dots-fadein .5s linear forwards;
    -moz-animation: loading-dots-fadein .5s linear forwards;
    -o-animation: loading-dots-fadein .5s linear forwards;
    -ms-animation: loading-dots-fadein .5s linear forwards;
    animation: loading-dots-fadein .5s linear forwards;
  }
  .loading-dots i {
    width: .9em;
    height: .9em;
    display: inline-block;
    vertical-align: middle;
    background: #e0e0e0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 0 .125em;
    -webkit-animation: loading-dots-middle-dots .5s linear infinite;
    -moz-animation: loading-dots-middle-dots .5s linear infinite;
    -o-animation: loading-dots-middle-dots .5s linear infinite;
    -ms-animation: loading-dots-middle-dots .5s linear infinite;
    animation: loading-dots-middle-dots .5s linear infinite;
  }
  .loading-dots.dark-gray i {
    background: #fff;
  }
  .loading-dots i:first-child {
    -webkit-animation: loading-dots-first-dot .5s infinite;
    -moz-animation: loading-dots-first-dot .5s linear infinite;
    -o-animation: loading-dots-first-dot .5s linear infinite;
    -ms-animation: loading-dots-first-dot .5s linear infinite;
    animation: loading-dots-first-dot .5s linear infinite;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translate(-1em);
    -moz-transform: translate(-1em);
    -o-transform: translate(-1em);
    -ms-transform: translate(-1em);
    transform: translate(-1em);
  }
  .loading-dots i:last-child {
    -webkit-animation: loading-dots-last-dot .5s linear infinite;
    -moz-animation: loading-dots-last-dot .5s linear infinite;
    -o-animation: loading-dots-last-dot .5s linear infinite;
    -ms-animation: loading-dots-last-dot .5s linear infinite;
    animation: loading-dots-last-dot .5s linear infinite;
  }


  @-moz-keyframes loading-dots-fadein{100%{opacity:1;-ms-filter:none;filter:none}}
  @-webkit-keyframes loading-dots-fadein{100%{opacity:1;-ms-filter:none;filter:none}}
  @-o-keyframes loading-dots-fadein{100%{opacity:1;-ms-filter:none;filter:none}}
  @keyframes loading-dots-fadein{100%{opacity:1;-ms-filter:none;filter:none}}

  @-moz-keyframes loading-dots-first-dot{100%{-webkit-transform:translate(1em);-moz-transform:translate(1em);-o-transform:translate(1em);-ms-transform:translate(1em);transform:translate(1em);opacity:1;-ms-filter:none;filter:none}}
  @-webkit-keyframes loading-dots-first-dot{100%{-webkit-transform:translate(1em);-moz-transform:translate(1em);-o-transform:translate(1em);-ms-transform:translate(1em);transform:translate(1em);opacity:1;-ms-filter:none;filter:none}}
  @-o-keyframes loading-dots-first-dot{100%{-webkit-transform:translate(1em);-moz-transform:translate(1em);-o-transform:translate(1em);-ms-transform:translate(1em);transform:translate(1em);opacity:1;-ms-filter:none;filter:none}}
  @keyframes loading-dots-first-dot{100%{-webkit-transform:translate(1em);-moz-transform:translate(1em);-o-transform:translate(1em);-ms-transform:translate(1em);transform:translate(1em);opacity:1;-ms-filter:none;filter:none}}


  @-moz-keyframes loading-dots-middle-dots{100%{-webkit-transform:translate(1em);-moz-transform:translate(1em);-o-transform:translate(1em);-ms-transform:translate(1em);transform:translate(1em)}}
  @-webkit-keyframes loading-dots-middle-dots{100%{-webkit-transform:translate(1em);-moz-transform:translate(1em);-o-transform:translate(1em);-ms-transform:translate(1em);transform:translate(1em)}}
  @-o-keyframes loading-dots-middle-dots{100%{-webkit-transform:translate(1em);-moz-transform:translate(1em);-o-transform:translate(1em);-ms-transform:translate(1em);transform:translate(1em)}}
  @keyframes loading-dots-middle-dots{100%{-webkit-transform:translate(1em);-moz-transform:translate(1em);-o-transform:translate(1em);-ms-transform:translate(1em);transform:translate(1em)}}


  @-moz-keyframes loading-dots-last-dot{100%{-webkit-transform:translate(2em);-moz-transform:translate(2em);-o-transform:translate(2em);-ms-transform:translate(2em);transform:translate(2em);opacity:0;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter:alpha(opacity=0)}}
  @-webkit-keyframes loading-dots-last-dot{100%{-webkit-transform:translate(2em);-moz-transform:translate(2em);-o-transform:translate(2em);-ms-transform:translate(2em);transform:translate(2em);opacity:0;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter:alpha(opacity=0)}}
  @-o-keyframes loading-dots-last-dot{100%{-webkit-transform:translate(2em);-moz-transform:translate(2em);-o-transform:translate(2em);-ms-transform:translate(2em);transform:translate(2em);opacity:0;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter:alpha(opacity=0)}}
  @keyframes loading-dots-last-dot{100%{-webkit-transform:translate(2em);-moz-transform:translate(2em);-o-transform:translate(2em);-ms-transform:translate(2em);transform:translate(2em);opacity:0;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter:alpha(opacity=0)}}
}

.modal-content {
  border-radius: 0;
  .audio-preview {
    text-align: center;
    margin: 20px 0 0 0;
  }
}

.modal-body {
  padding: 15px 15px 34px 15px;
}

.modal-dialog-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (max-width: 767px) {
  .modal-dialog-center {
    width: 100%;
  }
}

input.form-control.input-lg {
  border-radius: 0;
}

::-webkit-input-placeholder { /* WebKit browsers */
  color:    #cbcbcb;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #cbcbcb;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #cbcbcb;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  color:    #cbcbcb;
}
.form-group {
  margin-bottom: 8px;
}
.input-group {
  width: 100%;
}
.input-group-addon {
  border-radius: 0;
  color: @white-primary;
}
input {
  border-radius: 0;
  text-align: center;
  -moz-box-shadow:    inset 0 0 8px #000000;
  -webkit-box-shadow: inset 0 0 8px #000000;
  box-shadow:         inset 0 0 8px #000000;
}
.btn-lg, .btn-group-lg > .btn {
  border-radius: 0;
}

.btn:hover, .btn:focus, .btn.focus {
  color: @brand-primary;
  text-decoration: none;
  /*border: 0 solid transparent;*/
  /*box-shadow: none;*/
}
.nav > li > a:hover {
  text-decoration: none;
  background-color: @brand-primary;
}

.spinner {
  display: none;
}

.spinner img {
  margin: 0 auto;
  text-align: center;
  display: block;
}

/*
Login Page
*/

.login-container {
  position: absolute;
  top: 16%;
  #skull-sml {
    text-align: center;
    margin-bottom: 20px;
  }
  ::-webkit-input-placeholder { /* WebKit browsers */
    color:    #cbcbcb;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #cbcbcb;
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #cbcbcb;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    color:    #cbcbcb;
  }
  .form-group {
    margin-bottom: 8px;
  }
  .input-group {
    width: 100%;
  }
  .input-group-addon {
    border-radius: 0;
    color: @white-primary;
  }
  input {
    border-radius: 0;
    text-align: center;
    -moz-box-shadow:    inset 0 0 8px #666;
    -webkit-box-shadow: inset 0 0 8px #666;
    box-shadow:         inset 0 0 8px #666;
  }
  .btn-lg, .btn-group-lg > .btn {
    border-radius: 0;
  }
}

@media (max-width: @screen-xs-max) {
  .login-container {
    position: static;
    margin-top: 56px;
  }
}

/*
Breadcrumbs
*/
.breadcrumb-container {
  display: block;
  min-height: 0px;
  //height: 64px;
  padding: 20px 10px 0 8px;
  margin-bottom: 4px;
  background-color: @brand-primary-dark;

  .bread-crumbs {
    margin: 0 0 0 4px;
    padding: 0;
    list-style: none;
    font-size: 16px; // 20

  }
  li {
    margin: 0 0 0 8px;
    color: @black;
    font-weight: 700;
    display: inline-block;
  }
  a {
    font-weight: 700;
    color: @new-white;
  }
  a:hover, a:focus {
    text-decoration: none;
    color: @brand-primary;
  }

}

/*
Logo
*/
.brand-logo {
  //margin-top: 8px;
  position:relative;
  z-index: 3333;
}

/*
Project Title
*/
.project-title {
  text-align: center;
  font-weight: 700;
  margin: 20px 0 30px 0;
  font-size: 16px;
  color: @black;
}

/*
Dir Listings
*/
table {
  .sep {
    display: block;
    padding: 0;
    margin: 0;
    height: 4px;
    background: transparent;
  }
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 0 6px;
  line-height: 1.42857143;
  vertical-align: middle;
  border-top: 0 solid transparent;
  color: @new-white;
  a:hover, a:focus {
    text-decoration: none;
  }
}

table tr {
  background-color: @soft-black; /*@brand-primary-dark;*/
}

.dirs > tbody > tr > td {
  padding: 16px 10px !important;
}

.files > tbody > tr > td {
  padding: 16px 10px !important;
}

.dirs {
  margin-bottom: 0;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  tr {
    display:none;
    /*margin-bottom: 4px;*/
    cursor: pointer;
  }
  .selected {
    background: @selected-rows;
    a {
      color: @new-white;
    }
  }
  td {
    padding: 12px 0;
  }
  span.icon-gw {
    font-size: 32px; //48
    padding-left: 8px;
    padding-right: 18px;
    vertical-align: sub;
    color: @new-white;
  }
  span.icon-sml {
    font-size: 22px;
    padding-left: 8px;
    padding-right: 18px;
  }
  a {
    font-weight: 700;
    font-size: 13px;
    display: inline-block;
    color: @black;
  }
  a:hover{
    span.icon-gw {
      color: @brand-primary;
    }
  }
}


.files {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  tr {
    display:none;
    margin-bottom: 4px;
    cursor: pointer;
  }
  .selected {
    li {
      color: @new-white;
    }
    background: @selected-rows;
    li.strong-txt {
      color: @new-white;
    }
    li small {
      color: @new-white;
    }
  }
  .odd {
    //float: right;
    padding: 0;
    width: 100%;
  }
  .even {
    //width: 49.65%;
    width: 100%;
    padding: 0;
  }
  span.icon-gw {
    font-size: 32px; //48
    padding-left: 8px;
    padding-right: 18px;
    color: @new-white; /*@white-primary;*/
  }
  span.icon-sml {
    font-size: 22px;
    padding-left: 8px;
    padding-right: 18px;
    color: @brand-primary; /*@white-primary;*/
  }
  a {
    font-weight: 700;
    font-size: 16px;
  }
}
.file-info {
  position: relative;
  cursor: default;
}

.files-list {
  margin: 0;
  padding: 0;
  /*
  position: absolute;
  top: 18px;
  left: 80px;
  */
  display: inline-block;
  vertical-align: top; // bottom
  li {
    text-shadow: 1px 1px 1px lighten(@black, 25%);
  }
  li.strong-txt {
    //margin-bottom: 6px;
    font-size: 13px;
    color: @black;
    text-shadow: none;
  }
}

#upload-modal {
  .modal-header {
    text-align: center;
  }
  .modal-body {
    min-height: 300px;
    height: 300px;
  }
}

#drop-target-holder {
  /*min-height: 300px;*/
  height: 285px;
  width: 100%;
  display: block;
  position: relative;
  border: 2px dotted @soft-black;
  #drop-target-text {
    position: absolute;
    top: 40%;
    left: 0;
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 700;
    z-index: 9998;
    color: @black;
    span {
      font-size: 40px;
    }
  }
  #drop-target {
    top: 4px;
    left: -3px;
    width: 100%;
    height: 272px;
    position: absolute;
    z-index: 9999;
    background: transparent;
    overflow: auto;
    &:hover {
      /*background-color: fade(@selected-rows, 50%);*/
    }
    #file-list {
      overflow-y: auto;
      height: 282px;
      list-style: none;
      padding: 12px;
      margin: 0;
    }
    li {
      position: relative;
      .progress {
        height: 36px;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1), inset -1px -1px 4px rgba(0, 0, 0, 0.1);
        background-color: @softer-black;
        border-radius: 0;
        .progress-bar {
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.3), inset -1px -1px 4px rgba(0, 0, 0, 0.3);
        }
      }
    }
    .flow-file-name {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 9px;
      color: @new-white;
      text-shadow: 1px 1px 1px #666;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


@media (max-width: @screen-lg-min) {
  .files-list {
    display: block;
    margin-top: 16px;
  }
}

@media (max-width: @screen-md-min) {
  .files {
    .odd {
      float: none;
      clear: both;
      /*border-bottom: 4px solid @body-bg;*/
    }
    .even {
      /*border-bottom: 4px solid @body-bg;*/
      width: 100%;
    }
  }
}

span.icon-gw.icon-gw-libreoffice {
  font-size: 32px; //58
  //padding: 21px 10px 21px 5px;
}

span.file-ext {
  font-weight: 700;
  font-size: 11px;
  background-color: @brand-primary;//#94a8b2;
  color: @new-white; /*@brand-primary-dark;*/
  text-transform: uppercase;
  padding: 2px 10px;
  position: absolute;
  left: 2px; //3
  top: 18px; // 36
  text-align: center;
  width: auto; // 57
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -o-border-radius: 1px;
  -ms-border-radius: 1px;
  border-radius: 1px;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 0 solid transparent;
  }
}

/*
Tools Nav
*/

@media (min-width: @screen-sm-min) {
  .affix {
    position: fixed;
    top: 56px;
    z-index: 1039;
  }
}

@media (min-width: @screen-md-min) {
  .affix {
    position: fixed;
    top: 56px;
    width: 75px;
  }
}

@media (min-width: @screen-lg-min) {
  .affix {
    position: fixed;
    top: 56px;
    width: 91px;
  }
}

.tools-nav-container, .tools-nav-container-top {
  display: none;
  /*width: 100%;*/
  height: auto;
  /*padding-right: 11px;*/
  background-color: @soft-black; /*@brand-primary-dark;*/
  margin-bottom: 4px;
  .tools-nav {
    list-style: none;
    /*margin: 0 0 0 11px;*/
    padding: 0;
  }
  li {
    display: inline-block;
  }
  .btn {
    font-size: 38px;
    border-radius: 0;
  }
  a {
    color: @brand-primary;
    margin: 0 auto;
    display: block;
    text-align: center;
    span {
      vertical-align: middle;
      font-size: 32px;
    }
  }
  a:hover {
    color: @link-hover-color;
    text-decoration: none;
  }
}

.tools-nav-container-top {
  display: block;
}

@media (min-width: @screen-md-min) {
  .tools-nav-container {
    margin-bottom: 0;
    .tools-nav {
    }
    li {
      display: block;
    }
  }
}

//
// Helper Classes
//

.transparent-header {
  background-color: transparent;
  border: 0 solid transparent;
}

.transparent-footer {
  background-color: transparent;
}

.btn-custom {
  /*background-color: hsl(336, 100%, 55%) !important;*/
  background-color: @brand-primary;
  /*
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@brand-primary-dark", endColorstr="@brand-primary");
  background-image: -khtml-gradient(linear, left top, left bottom, from(@brand-primary-dark), to(@brand-primary));
  background-image: -moz-linear-gradient(top, @brand-primary-dark, @brand-primary);
  background-image: -ms-linear-gradient(top, @brand-primary-dark, @brand-primary);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @brand-primary-dark), color-stop(100%, @brand-primary));
  background-image: -webkit-linear-gradient(top, @brand-primary-dark, @brand-primary);
  background-image: -o-linear-gradient(top, @brand-primary-dark, @brand-primary);
  background-image: linear-gradient(@brand-primary-dark, @brand-primary);
  */
  border-color: @brand-sec;
  color: @new-white !important;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  -webkit-font-smoothing: antialiased;
}

.btn:hover {
  /*background-position: 0 -46px;*/
}

.btn {
  transition: 0.1s linear all;
}

.addTopPadding {
  padding-top: 88px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-right-5 {
  padding-right: 5px;
}

.less-col-padding {
  padding-left: 5px;
  padding-right: 5px;
}

.no-left-right-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.strong-txt {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.transparent-bg {
  background: transparent;
}

.no-box-shadow {
  box-shadow: none;
}

@media (max-width: @screen-xs-max) {
  .addTopPadding {
    padding-top: 168px;
  }
}
