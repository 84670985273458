body {
	-webkit-backface-visibility: hidden;
}
.animated {
	-webkit-animation-duration: @animationLessTime;
	   -moz-animation-duration: @animationLessTime;
	     -o-animation-duration: @animationLessTime;
	        animation-duration: @animationLessTime;
	-webkit-animation-fill-mode: both;
	   -moz-animation-fill-mode: both;
	     -o-animation-fill-mode: both;
	        animation-fill-mode: both;
}