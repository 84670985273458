@font-face {
  font-family: 'germanwahn';
  src:url('../fonts/germanwahn.eot?-ov7eef');
  src:url('../fonts/germanwahn.eot?#iefix-ov7eef') format('embedded-opentype'),
  url('../fonts/germanwahn.woff?-ov7eef') format('woff'),
  url('../fonts/germanwahn.ttf?-ov7eef') format('truetype'),
  url('../fonts/germanwahn.svg?-ov7eef#germanwahn') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-gw {
  font-family: 'germanwahn';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gw-home:before {
  content: "\e900";
  /*vertical-align:middle;*/
}

.icon-gw-file-empty:before {
  content: "\e924";
  /*vertical-align:middle;*/
}

.icon-gw-folder:before {
  content: "\e92f";
  /*vertical-align:middle;*/
}

.icon-gw-folder-open:before {
  content: "\e930";
  /*vertical-align:middle;*/
}

.icon-gw-folder-plus:before {
  content: "\e931";
  /*vertical-align:middle;*/
}

.icon-gw-folder-minus:before {
  content: "\e932";
  /*vertical-align:middle;*/
}

.icon-gw-folder-download:before {
  content: "\e933";
  /*vertical-align:middle;*/
}

.icon-gw-folder-upload:before {
  content: "\e934";
  /*vertical-align:middle;*/
}

.icon-gw-drawer:before {
  content: "\e95c";
  /*vertical-align:middle;*/
}

.icon-gw-drawer2:before {
  content: "\e95d";
  /*vertical-align:middle;*/
}

.icon-gw-box-add:before {
  content: "\e95e";
  /*vertical-align:middle;*/
}

.icon-gw-box-remove:before {
  content: "\e95f";
  /*vertical-align:middle;*/
}

.icon-gw-download:before {
  content: "\e960";
  /*vertical-align:middle;*/
}

.icon-gw-upload:before {
  content: "\e961";
  /*vertical-align:middle;*/
}

.icon-gw-drive:before {
  content: "\e963";
  /*vertical-align:middle;*/
}

.icon-gw-database:before {
  content: "\e964";
  /*vertical-align:middle;*/
}

.icon-gw-undo2:before {
  content: "\e967";
  /*vertical-align:middle;*/
}

.icon-gw-reply:before {
  content: "\e96a";
  /*vertical-align:middle;*/
}

.icon-gw-search:before {
  content: "\e986";
  /*vertical-align:middle;*/
}

.icon-gw-enlarge2:before {
  content: "\e98b";
  /*vertical-align:middle;*/
}

.icon-gw-shrink2:before {
  content: "\e98c";
  /*vertical-align:middle;*/
}

.icon-gw-bin:before {
  content: "\e9ac";
  /*vertical-align:middle;*/
}

.icon-gw-bin2:before {
  content: "\e9ad";
  /*vertical-align:middle;*/
}

.icon-gw-menu3:before {
  content: "\e9bf";
  /*vertical-align:middle;*/
}

.icon-gw-menu4:before {
  content: "\e9c0";
  /*vertical-align:middle;*/
}

.icon-gw-download2:before {
  content: "\e9c5";
  /*vertical-align:middle;*/
}

.icon-gw-upload2:before {
  content: "\e9c6";
  /*vertical-align:middle;*/
}

.icon-gw-download3:before {
  content: "\e9c7";
  /*vertical-align:middle;*/
}

.icon-gw-upload3:before {
  content: "\e9c8";
  /*vertical-align:middle;*/
}

.icon-gw-sphere:before {
  content: "\e9c9";
  /*vertical-align:middle;*/
}

.icon-gw-eye:before {
  content: "\e9ce";
  /*vertical-align:middle;*/
}

.icon-gw-eye-blocked:before {
  content: "\e9d1";
  /*vertical-align:middle;*/
}

.icon-gw-checkmark:before {
  content: "\ea10";
  /*vertical-align:middle;*/
}

.icon-gw-checkmark2:before {
  content: "\ea11";
  /*vertical-align:middle;*/
}

.icon-gw-exit:before {
  content: "\ea14";
  /*vertical-align:middle;*/
}

.icon-gw-circle-up:before {
  content: "\ea41";
  /*vertical-align:middle;*/
}

.icon-gw-circle-right:before {
  content: "\ea42";
  /*vertical-align:middle;*/
}

.icon-gw-circle-down:before {
  content: "\ea43";
  /*vertical-align:middle;*/
}

.icon-gw-circle-left:before {
  content: "\ea44";
  /*vertical-align:middle;*/
}

.icon-gw-shift:before {
  content: "\ea4f";
  /*vertical-align:middle;*/
}

.icon-gw-checkbox-checked:before {
  content: "\ea52";
  /*vertical-align:middle;*/
}

.icon-gw-checkbox-unchecked:before {
  content: "\ea53";
  /*vertical-align:middle;*/
}

.icon-gw-radio-checked:before {
  content: "\ea54";
  /*vertical-align:middle;*/
}

.icon-gw-radio-checked2:before {
  content: "\ea55";
  /*vertical-align:middle;*/
}

.icon-gw-radio-unchecked:before {
  content: "\ea56";
  /*vertical-align:middle;*/
}

.icon-gw-new-tab:before {
  content: "\ea7e";
  /*vertical-align:middle;*/
}

.icon-gw-libreoffice:before {
  content: "\eade";
  /*vertical-align:middle;*/
}

